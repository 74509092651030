import React, {useEffect, useRef, useState} from 'react';
import NewsProp from "../news/NewsProp";
import {news_api} from "../../api/news_api";

// Component (here it's a view)
function News() {
    // Updatable variable
    const [allnews, setNews] = useState([]);
    const news = allnews.map((news) =>
        <NewsProp key={news.id} news = {news}/>
    );

    // Function called on load of the component...
    useEffect(() => {
        // Example of fetch API data
        async function fetchData() {
            const resp = await news_api.getNewsWithLimit(12);
            setNews(resp);
        }
        fetchData();
    }, []);

    // DOM
    return (
        <div className='grid container mx-auto grid-cols-1 md:grid-cols-2 gap-10'>
            {news}
        </div>
    );
}

export default News;