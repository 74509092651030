import React, { useEffect, useState } from 'react';
import { schedules_api } from "../../api/schedules_api";
import ScheduleProp from "../schedule/ScheduleProp";

function Schedules() {
    const weekday = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    const weekdayOrder = {
        "dimanche": 0,
        "lundi": 1,
        "mardi": 2,
        "mercredi": 3,
        "jeudi": 4,
        "vendredi": 5,
        "samedi": 6
    };

    const d = new Date();
    let day = weekday[d.getDay()];

    const [orderedSchedules, setOrderedSchedules] = useState([]);
    const [isAnimating, setIsAnimating] = useState(false);

    const sortSchedulesByWeekday = (schedules) => {
        return schedules.sort((a, b) => weekdayOrder[a.weekday.toLowerCase()] - weekdayOrder[b.weekday.toLowerCase()]);
    };

    const getOrderedSchedules = (schedules) => {
        const todayIndex = d.getDay();
        let ordered = [];

        let currentScheduleIndex = schedules.findIndex(s => weekdayOrder[s.weekday.toLowerCase()] === todayIndex);

        if (currentScheduleIndex !== -1) {
            ordered = schedules.slice(currentScheduleIndex).concat(schedules.slice(0, currentScheduleIndex));
        } else {
            let nextScheduleIndex = schedules.findIndex(s => weekdayOrder[s.weekday.toLowerCase()] > todayIndex);
            if (nextScheduleIndex === -1) {
                nextScheduleIndex = 0;
            }
            ordered = schedules.slice(nextScheduleIndex).concat(schedules.slice(0, nextScheduleIndex));
        }
        return ordered;
    };

    useEffect(() => {
        async function fetchData() {
            const resp = await schedules_api.getSchedules();
            const sortedSchedules = sortSchedulesByWeekday(resp);
            const finalOrderedSchedules = getOrderedSchedules(sortedSchedules);
            setOrderedSchedules(finalOrderedSchedules);
        }
        fetchData();
    }, []);

    // Gestion du clic pour déclencher l'animation
    const handleClick = () => {
        if (isAnimating) return; // Empêche les clics multiples pendant l'animation
        setIsAnimating(true);
    };

    // Mise à jour de la pile après l'animation
    useEffect(() => {
        if (isAnimating) {
            const timer = setTimeout(() => {
                setOrderedSchedules((prevSchedules) => {
                    if (prevSchedules.length === 0) return prevSchedules;
                    const [firstSchedule, ...restSchedules] = prevSchedules;
                    return [...restSchedules, firstSchedule];
                });
                setIsAnimating(false);
            }, 500); // La durée doit correspondre à celle de l'animation
            return () => clearTimeout(timer);
        }
    }, [isAnimating]);

    return (
        <div>
            <h2 className={"md:hidden text-3xl sm:text-4xl font-bold pb-5"}>
                <a className='font-outfit' href={"/horaires"}>PROCHAINS HORAIRES</a>
            </h2>
            <h2 className={"hidden md:block text-3xl sm:text-4xl font-bold pb-5"}>
                <a className='font-outfit' href={"/horaires"}>HORAIRES</a>
            </h2>
            <div onClick={handleClick} className="relative cursor-pointer mt-0">
                {orderedSchedules.map((schedule, index) => {
                    const zIndex = orderedSchedules.length - index;
                    const widthPercentage = 100 - (index * 5);
                    const marginTop = index * 12;
                    const marginBottom = -40 - (index * 12);

                    // Appliquer l'animation au premier élément si l'animation est en cours
                    const animationClass = isAnimating && index === 0 ? 'animate-slideUpAndFade' : '';
                    const animationClassNext = isAnimating && index === 1 ? 'animate-zoom-100' : '';
                    const animationClassNextNext = isAnimating && index === 2 ? 'animate-zoom-95' : '';
                    const animationClassNextNextNext = !isAnimating && index === 2 ? 'animate-zoom-slide-up' : '';
                    const hidden = index > 2 ? 'hidden' : '';

                    return (
                        <div
                            key={schedule.id}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: `${widthPercentage}%`,
                                zIndex: zIndex,
                                marginTop: `${marginTop}px`,
                                marginBottom: `${marginBottom}px`,
                                height: '10rem',
                            }}
                            className={`${hidden} shadow-lg bg-gradient-to-r from-green-400 to-green-600 overflow-hidden ${animationClass} ${animationClassNext} ${animationClassNextNext} ${animationClassNextNextNext}`}
                        >
                            <ScheduleProp schedule={schedule} />
                        </div>
                    );
                })}
            </div>
            <div className={"mt-52 text-right font-outfit font-extralight md:mb-1"}>
                <a href={"/horaires"}>
                    → Cliquez ici pour voir tous les horaires
                </a>
            </div>
        </div>
    );
}

export default Schedules;
