import React from 'react';
import Schedules from "../components/home/Schedules";
import IdeaBox from "../components/home/IdeaBox";
import News from "../components/home/News";
import Albums from "../components/home/Albums";
import {Helmet} from "react-helmet";
import Calendar from "../components/home/Calendar";

// Component (here it's a view)
function Home() {
    // Updatable variable

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Association Sportive de Flavigny</title>

                <meta name="title" content="Association Sportive de Flavigny" />
                <meta name="description" content="Le badminton club de Flavigny (ASF), situé au sud de Nancy (près de Ludres et Richardménil), vous accueille dans une ambiance conviviale." />
                <meta name="robots" content="noimageindex" />

                <meta property="og:title" content="Association Sportive de Flavigny" />
                <meta property="og:site_name" content="Association Sportive de Flavigny" />
                <meta property="og:description" content="Le badminton club de Flavigny (ASF), situé au sud de Nancy (près de Ludres et Richardménil), vous accueille dans une ambiance conviviale." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />

                <link rel="canonical" href="/" />
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>
            <div className={"md:hidden mt-5 lg:-mt-24"}>
                <Schedules/>
            </div>
            <div className={"flex mt-10 space-x-16"}>
                <div className={"md:w-2/3"}>
                    <div className={"md:hidden mb-16"}>
                        <h2 className={"text-3xl font-bold font-outfit pb-5"}>
                            <a href={"/evenements"}>CALENDRIER</a>
                        </h2>
                        <Calendar/>
                    </div>
                    <h2 className={"text-3xl sm:text-4xl font-bold font-outfit pb-5"}>
                        <a href={"/actus"}>ACTUALITES</a>
                    </h2>
                    <News/>
                    <a href={"/actus"}>
                        <div className="flex justify-end mb-16">
                            <button className="font-extralight font-outfit">
                                → Voir plus d'actualités
                            </button>
                        </div>
                    </a>
                    <div className={"md:hidden"}>
                        <h2 className={"text-3xl sm:text-4xl font-bold font-outfit pb-5"}>
                            <a href={"/albums"}>ALBUMS</a>
                        </h2>
                        <Albums/>
                        <a href={"/albums"}>
                            <div className="flex justify-end mb-16">
                                <button className="font-extralight font-outfit">
                                    → Voir plus d'albums
                                </button>
                            </div>
                        </a>
                    </div>
                    <div className={"md:hidden mb-5"}>
                        <IdeaBox/>
                    </div>
                </div>
                <div className={"w-1/3 hidden md:block"}>
                    <Schedules/>
                    <h2 className={"mt-10 text-2xl sm:text-4xl font-bold font-outfit pb-5"}>
                        <a href={"/evenements"}>CALENDRIER</a>
                    </h2>
                    <Calendar/>
                    <div className={"mt-12"}>
                        <IdeaBox/>
                    </div>
                    <h2 className={"mt-10 text-4xl font-bold font-bold font-outfit pb-5"}>
                        <a href={"/albums"}>ALBUMS</a>
                    </h2>
                    <Albums/>
                    <a href={"/albums"}>
                        <div className="flex justify-end mb-16">
                            <button className="font-extralight font-outfit">
                                → Voir plus d'albums
                            </button>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Home;
