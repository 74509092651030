import React from 'react';

function Photo (props) {

    return(
        <div>
            {props.hasPrev &&
                <button onClick={props.onPrev} className="absolute left-0 top-1/2 transform -translate-y-1/2 text-4xl text-white bg-black bg-opacity-50 px-2 py-1">
                    &#10094; {/* Code HTML pour la flèche gauche */}
                </button>
            }
            <img className={"max-w-screen max-h-screen"} src={"/content" + props.img} alt={"Photo de l'album " + props.album}/>
            {props.hasNext &&
                <button onClick={props.onNext} className="absolute right-0 top-1/2 transform -translate-y-1/2 text-4xl text-white bg-black bg-opacity-50 px-2 py-1">
                    &#10095; {/* Code HTML pour la flèche droite */}
                </button>
            }
        </div>
    );
}

export default Photo;
