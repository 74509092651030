import React, {useEffect, useRef, useState} from 'react';
import AlbumProp from "../components/albums/AlbumProp";
import {album_api} from "../api/album_api";
import PopUp from "../components/global/PopUp";
import AlbumForm from "../components/form/AlbumForm";
import {Helmet} from "react-helmet";

function Albums () {

    const [allalbums, setAlbums] = useState([]);
    const albums = allalbums.map((album) =>
        <AlbumProp key={album.id} album = {album}/>
    );

    const [limit] = useState(6); // Number of news to fetch [default: 5
    const [offset, setOffset] = useState(0); // Number of news to skip [default: 0]
    const [hasMore, setHasMore] = useState(true); // Boolean to know if there is more news to fetch

    const popUpRef = useRef();

    // Function called on load of the component...
    async function fetchData(offset = 0) {
        const resp = await album_api.getAlbumsWithLimitAndOffset(limit, offset);
        if (resp.length === 0 || resp.length % limit !== 0) {
            await setHasMore(false);
        }
        // set resp to existing news
        setAlbums([...allalbums, ...resp]);
    }

    useEffect(() => {
        // Example of fetch API data
        fetchData();
    }, []);

    const showMore = () => {
        if (!hasMore) return;
        setOffset(offset + limit);
        fetchData(offset + limit);
    }

    window.onscroll = function (ev) {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
        if (bottom) {
            showMore();
        }
    }

    return(
        <div className="mt-6 mb-10">
            <Helmet>
                <meta property="og:title" content="Albums du club | ASFlavigny" />
                <meta property="og:site_name" content="Albums du club | ASFlavigny" />
                <meta property="og:description" content="Retrouvez les albums photo du club de badminton de Flavigny (ASF)." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://asfbad54.fr/albums" />
                <meta property="og:image" content="https://asfbad54.fr/img/logo-blue.png" />
        
                <meta charSet="utf-8" />
                <title>Albums du club | ASFlavigny</title>
                <meta name="description" content="Retrouvez les albums photo du club de badminton de Flavigny (ASF)." />
                <link rel="canonical" href="/albums"/>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>

            <PopUp ref={popUpRef} children={<AlbumForm sended={() => {popUpRef.current.closePopup();}} />}/>
            <div className={"grid container mx-auto grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10"}>
                {localStorage.getItem('username') !== null &&
                    <div>
                        <div className={"inline-block relative w-full"}>
                            <div style={{marginTop: '100%'}}></div>
                            <div className={"absolute top-0 left-0 right-0 bottom-0"}>
                                <div onClick={() => popUpRef.current.seePopup()} className="bg-no-repeat bg-center bg-cover w-full h-full cursor-pointer" style={{backgroundImage: "url('/img/add.jpg')"}}>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {albums}
            </div>
            <div>
                {hasMore &&
                    <div className="flex justify-center">
                        <button onClick={() => showMore()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">
                            Voir plus
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default Albums;
